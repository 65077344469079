var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.sideboxes, function(sidebox, index) {
        return _c(
          "gov-inset-text",
          { key: sidebox.index },
          [
            _c("ck-text-input", {
              attrs: {
                value: sidebox.title,
                id: "sideboxes." + index + ".title",
                label: "Title",
                error: _vm.errors.get("sideboxes." + index + ".title")
              },
              on: {
                input: function($event) {
                  return _vm.onTitleInput({ index: index, title: $event })
                }
              }
            }),
            _c("ck-wysiwyg-input", {
              attrs: {
                value: sidebox.content,
                id: "sideboxes." + index + ".content",
                label: "Sidebox Content",
                error: _vm.errors.get("sideboxes." + index + ".content"),
                extensions: _vm.extensions
              },
              on: {
                input: function($event) {
                  return _vm.onContentInput({ index: index, content: $event })
                }
              }
            }),
            _c(
              "gov-button",
              {
                attrs: { error: "" },
                on: {
                  click: function($event) {
                    return _vm.onDelete(index)
                  }
                }
              },
              [_vm._v("Delete")]
            )
          ],
          1
        )
      }),
      _c(
        "div",
        [
          _c(
            "gov-button",
            {
              attrs: { disabled: _vm.sideboxes.length >= 3 },
              on: { click: _vm.onAdd }
            },
            [
              _vm.sideboxes.length === 0
                ? [_vm._v("Add sidebox")]
                : [_vm._v("Add another")]
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }